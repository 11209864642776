import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'

import { useParams } from '/machinery/Params'
import { URLGeneratorProvider } from '/machinery/URLGenerator'
import { SearchContextProvider } from '/machinery/SearchContext'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'

import { DeckFeed } from '/features/regionArticles/DeckFeed'
import { Loader } from '/features/pageOnly/Loader'

import mediaStyles from '/cssGlobal/media.css'
import styles from './DeckFeedApp.css'

const queryClient = new QueryClient()

export function DeckFeedApp({ items, feedData, issues, specials, urlGeneratorData }) {
  const mounted = useRenderOnMount()
  const preloadedMediaQueries = usePreloadedMediaQueries()
  const { region, title } = feedData
  const { issue }  = useParams()

  if (!mounted || !preloadedMediaQueries) return (
    <div className={styles.app}>
      <Loader layoutClassName={styles.loaderLayout} />
    </div>
  )

  return (
    <URLGeneratorProvider {...urlGeneratorData}>
      <SearchContextProvider {...{ feedData, issue }}>
        <QueryClientProvider client={queryClient}>
          <DeckFeed {...{ items, title, region, issues, specials }} />
        </QueryClientProvider>
      </SearchContextProvider>
    </URLGeneratorProvider>
  )
}

function usePreloadedMediaQueries() {
  return [
    useMediaQuery(mediaStyles.viewportMd),
    useMediaQuery(mediaStyles.viewportLg),
  ].every(x => x !== null)
}
