import { animated, useSpring } from '@react-spring/web'
import { useGesture } from '@use-gesture/react'
import { useElementSize } from '@kaliber/use-element-size'
import { Credits } from '/features/buildingBlocks/Credits'

import { useIsActiveSignal, useIsActive } from '/machinery/IsActive'
import { useSignalDrivenTrail } from '/machinery/useSignalDrivenSpring'
import { useCardAnimations } from '/features/regionArticles/cards/useCardAnimations'
import { useItemUrl } from '/features/regionArticles/useItemUrl'

import { AudioPlayerDeckFeed } from '/features/gedicht/buildingBlocks/AudioPlayer'
import { ImageCover } from '/features/buildingBlocks/Image'
import { Icon } from '/features/buildingBlocks/Icon'

import iconArrowRight from '/images/icons/arrow-right.raw.svg'

import styles from './CardGedicht.css'
import { useTranslate } from '/machinery/I18n'

export function CardGedicht({ item, layoutClassName }) {
  const url = useItemUrl(item)

  const { content, metadata } = item
  const { credits } = metadata
  const { image, title } = content.hero // TODO: Erik / Lars - volgens mij klopt de title van de poem niet, moeten we even de originele data checken
  const { poem, author, position, description, audio } = content // TODO: Erik / Erwin - overleggen over de image, misschien met Lars

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <Content
        layoutClassName={styles.contentLayout}
        {...{ url, image, title, credits, poem, author, position, description, audio }}
      />
    </div>
  )
}

// TODO: Erik / Lars / Erik - we komen hier mogelijk in de problemen met credits vs author / position, even goed kijken hoe we de data normaliseren
function Content({ url, poem, description, audio, image, title, credits, layoutClassName }) {
  const { __ } = useTranslate()

  const animations = useCardAnimations()
  const isActive = useIsActive()

  const [toggle, setToggle] = React.useState(false)

  const [style, api] = useSpring(() => ({
    scale: 1.3,
    config: { duration: 35000 }
  }))

  React.useEffect(
    () => {
      api.start({
        scale: toggle ? 1.3 : 1,
        reverse: toggle,
        onRest: () => setToggle(!toggle)
      })

      if (isActive) api.resume()
      else api.pause()
    },
    [api, isActive, toggle]
  )

  // TODO: Erik - use WoodwingContent here to render things
  return (
    <div className={cx(styles.componentContent, layoutClassName)}>
      <Gedicht content={poem} layoutClassName={styles.gedichtLayout} {...{ title, isActive }} />

      <animated.div className={styles.imageContainer} {...{ style }}>
        <ImageCover aspectRatio={16 / 9} layoutClassName={styles.imageLayout} {...{ image }} />
      </animated.div>

      {audio && <AudioPlayerDeckFeed src={audio.src} layoutClassName={styles.audioPlayerLayout} />}

      <Credits layoutClassName={styles.creditsLayout} {...{ credits, description }} />

      {url && (
        <Link
          label={__`link-to-poem`}
          animation={animations.navigationButton}
          layoutClassName={styles.navigationButtonLayout}
          {...{ url }}
        />
      )}
    </div>
  )
}

function Link({ label, url, animation, layoutClassName }) {
  return (
    <animated.a className={cx(styles.componentLink, layoutClassName)} data-x='link-to-detail' style={animation} href={url}>
      <span className={styles.screenreaderOnly}>{label}</span>
      <Icon icon={iconArrowRight} />
    </animated.a>
  )
}

function Gedicht({ isActive, title, content, layoutClassName }) {
  const { contentAnimation, bindGestures, contentSizeRef, containerSizeRef } = useScrollContainerGestures({ isActive })

  return (
    <div className={cx(styles.componentGedicht, layoutClassName)} ref={containerSizeRef}>
      <animated.div
        ref={contentSizeRef}
        style={contentAnimation}
        className={cx(styles.text, styles.textLayout)}
        {...bindGestures()}
      >
        <Heading {...{ title }} />
        <RevealingText lines={content.lines} />
      </animated.div>
    </div>
  )
}

function Heading({ title }) {
  return <h2 className={styles.componentHeading}>{title}</h2>
}

function RevealingText({ lines }) {
  const isActive$ = useIsActiveSignal()
  // TODO: this might be problematic when the user scrolled, part of the animation will happen outside
  //       we could consider doing this with the mask instead
  const revealTrail = useSignalDrivenTrail(lines.length, isActive$, isActive => ({
    opacity: isActive ? 1 : 0,
    config: { mass: 1, tension: 280, friction: 35 }
  }))

  return revealTrail.map((props, i) => (
    <animated.div key={i} style={props}>
      <p>{lines[i]}</p>
    </animated.div>
  ))
}

function useScrollContainerGestures({ isActive }) {
  const { size: { height: contentHeight }, ref: contentSizeRef } = useElementSize()
  const { size: { height: containerHeight }, ref: containerSizeRef } = useElementSize()
  const [{ y }, api] = useSpring(() => ({ y: 0 }))

  const bindGestures = useGesture(
    {
      onDrag({ active, event, down, offset: [, offsetY], overflow: [, overflowY] }) {
        if (!isActive) return
        if (!overflowY && active) event.stopPropagation()
        if (!active) return

        if (down) api.start({ y: offsetY })
      },
      onWheel({ active, event, offset: [, offsetY], movement: [, movementY], overflow: [, overflowY] }) {
        if (!isActive) return
        if (!overflowY && active) event.stopPropagation()
        if (!active) return

        api.start({ y: -offsetY })
      },
    },
    {
      wheel: {
        triggerAllEvents: true,
        bounds: { top: 0, bottom: contentHeight - (containerHeight / 2) }
      },
      drag: {
        triggerAllEvents: true,
        bounds: { top: -contentHeight + (containerHeight / 2), bottom: 0 },
        axis: 'y',
      }
    }
  )

  return { contentAnimation: { y }, bindGestures, contentSizeRef, containerSizeRef }
}
